export const Icons = {
  "account-details": require("../assets/icons/account-details-min.png"),
  back: require("../assets/icons/back-min.png"),
  "bank-details": require("../assets/icons/bank-details-min.png"),
  support: require("../assets/icons/support-min.png"),
  "my-subscription": require("../assets/icons/my-subscription-min.png"),
  "white-subscription": require("../assets/icons/white-subscription-min.png"),
  "white-download": require("../assets/icons/white-download-min.png"),
  "rate-app": require("../assets/icons/rate-app-min.png"),
  "refer-earn": require("../assets/icons/refer-earn-min.png"),
  "tutorial-video": require("../assets/icons/tutorial-video-min.png"),
  wallet: require("../assets/icons/wallet-min.png"),
  "wallet-grey": require("../assets/icons/wallet-grey-min.png"),
  "wallet-white": require("../assets/icons/wallet-white.png"),
  logout: require("../assets/icons/logout-min.png"),
  "filled-building": require("../assets/icons/filled-building-min.png"),
  "black-building": require("../assets/icons/building-min.png"),
  "cir-white": require("../assets/icons/cir-min.png"),
  "filled-cir": require("../assets/icons/filled-cir-min.png"),
  "cir-gray": require("../assets/icons/CIR.png"),
  invoice: require("../assets/icons/invoice-min.png"),
  "invoice-blue": require("../assets/icons/invoice-blue-min (1).png"),
  "cir-blue": require("../assets/icons/cir-blue-min.png"),
  "black-dashboard": require("../assets/icons/black-dashboard-min.png"),
  "dashboard-filled": require("../assets/icons/dashboard-filled-min.png"),
  "transations-grey": require("../assets/icons/transations.png"),
  "legal-notice-grey": require("../assets/icons/legalNotice.png"),
  "filled-transctions": require("../assets/icons/transctions-filles.png"),
  "total-Defaulters-Reported": require("../assets/icons/total-Defaulters-Reported.png"),
  "amount-Settled": require("../assets/icons/amount-Settled.png"),
  "No-of-Customers": require("../assets/icons/No-of-Customers-01.png"),
  "registered-businesses": require("../assets/icons/registered-businesses.png"),
  "positive-response-rfrom-defaulters": require("../assets/icons/positive-response-rfrom-defaulters.png"),
  "total-amount-reported-as-default": require("../assets/icons/total-amount-reported-as-default.png"),
  "white-add-cash": require("../assets/icons/white-add-cash-min.png"),
  "white-defaulters": require("../assets/icons/white-defaulters-min.png"),
  "black-defaulters": require("../assets/icons/black-defaulters-min.png"),
  rupees: require("../assets/icons/rupee-min.png"),
  add: require("../assets/icons/add.png"),
  "search-blue": require("../assets/icons/searching-min.png"),
  "right-arrow": require("../assets/icons/right-arrow-min.png"),
  unselected: require("../assets/icons/unselected-min.png"),
  selected: require("../assets/icons/selected.png"),
  circle: require("../assets/icons/circle-min.png"),
  settlements: require("../assets/icons/settlements-min.png"),
  pencil: require("../assets/icons/pencil-min.png"),
  upgradePlanIcons: require("../assets/icons/icons8-upgrade-80.png"),
  tutorial: require("../assets/icons/tutorial-min.png"),
  "white-building": require("../assets/icons/white-building-min.png"),
  folder: require("../assets/icons/folder-min.png"),
  view: require("../assets/icons/view-min.png"),
  resendMail: require("../assets/icons/resendMail.png"),
  ticketform: require("../assets/icons/ticketformIcon.png"),
  confirmation: require("../assets/icons/confirmation-image.png"),
  delete: require("../assets/icons/delete-min.png"),
  "check-mark-circle-green": require("../assets/icons/check-mark-circle-min (1).png"),
  edit: require("../assets/icons/edit.png"),
  "close-min": require("../assets/icons/close-min-min (1).png"),
  pdf: require("../assets/icons/pdf-min (1).png"),
  darkPdf: require("../assets/icons/pdf.png"),
  "status-brown": require("../assets/icons/total-Defaulters-brown.png"),
  "status-orange": require("../assets/icons/total-Defaulters-orange.png"),
  "status-green": require("../assets/icons/more-document-green.png"),
  "status-red": require("../assets/icons/more-document-red.png"),
  "status-yellow": require("../assets/icons/more-document-yellow.png"),
  "status-gray": require("../assets/icons/settlement.png"),
  "status-blue": require("../assets/icons/more-document.png"),
  "check-mark-circle": require("../assets/icons/check-mark-circle-min.png"),
  download: require("../assets/icons/download.png"),
  affilate: require("../assets/icons/affilate.png"),
  facebook: require("../assets/icons/facebook.png"),
  insta: require("../assets/icons/insta.png"),
  linkedin: require("../assets/icons/linkedin.png"),
  twitter: require("../assets/icons/twitter.png"),
  whatsapp: require("../assets/icons/whatsapp.png"),
  youtube: require("../assets/icons/youtube.png"),
  pinterest: require("../assets/icons/pinterest.png"),
  "arrow-hand-right": require("../assets/icons/arrow-hand-right.png"),
  plus: require("../assets/icons/plus.png"),
  loadingIcon: require("../assets/images/loading.gif"),

  "arrow-hand-left": require("../assets/icons/arrow-hand.png"),
  "purchase-transaction-blue": require("../assets/icons/purchase-transaction-blue.png"),
  "purchase-transaction": require("../assets/icons/purchase-transaction.png"),
  "process-legal": require("../assets/icons/process-legal.png"),
  // black Icons
  "credit-transaction-dark": require("../assets/icons/credit-transaction-dark.png"),
  "block-mobile": require("../assets/icons/block -mobile.png"),
  "template-gray": require("../assets/icons/template-gray.png"),
  "credit-transaction": require("../assets/icons/credit-transaction.png"),
  "black-ad-campaign": require("../assets/icons/black-ad-campaign.png"),
  "black-admin-role": require("../assets/icons/black-admin-role.png"),
  "black-affiliates": require("../assets/icons/black-affiliates.png"),
  "black-audit": require("../assets/icons/black-audit.png"),
  "black-blog": require("../assets/icons/black-blog.png"),
  "black-career": require("../assets/icons/black-career.png"),
  "black-cities": require("../assets/icons/black-cities.png"),
  "black-commission": require("../assets/icons/black-commission.png"),
  "black-customers": require("../assets/icons/black-customers.png"),
  "black-FAQ": require("../assets/icons/black-FAQ.png"),
  "black-FAQ2": require("../assets/icons/black-FAQ2.png"),
  "black-homepage": require("../assets/icons/black-homepage.png"),
  "black-invoice": require("../assets/icons/black-invoice.png"),
  "black-membership-plan": require("../assets/icons/black-membership-plan.png"),
  "black-pages": require("../assets/icons/black-pages.png"),
  "black-partners": require("../assets/icons/black-partners.png"),
  "black-promo-code": require("../assets/icons/black-promo-code.png"),
  "black-user-manual": require("../assets/icons/black-user-manual.png"),
  "black-professionals": require("../assets/icons/black-professionals.png"),
  "black-about": require("../assets/icons/black-about.png"),
  "black-admin-management": require("../assets/icons/black-admin-management.png"),
  "black-Business-categories": require("../assets/icons/black-Business-categories.png"),
  "black-business": require("../assets/icons/black-business.png"),
  "black-cashback": require("../assets/icons/black-cashback.png"),
  "black-channel-partner": require("../assets/icons/black-channel-partner.png"),
  "black-contact": require("../assets/icons/black-contact.png"),
  "black-contant-management": require("../assets/icons/black-contant-management.png"),
  "black-list": require("../assets/icons/black-list.png"),
  "black-master-data": require("../assets/icons/black-master-data.png"),
  "black-media": require("../assets/icons/black-media.png"),
  "black-partner-management": require("../assets/icons/black-partner-management.png"),
  "black-promotion-management": require("../assets/icons/black-promotion-management.png"),
  "black-setting": require("../assets/icons/black-setting.png"),
  "black-testimonial": require("../assets/icons/black-testimonial.png"),
  "black-user-management": require("../assets/icons/black-user-management.png"),
  "black-wallet-cashback": require("../assets/icons/black-wallet-cashback.png"),
  "black-free-trial": require("../assets/icons/black-free-trial.png"),
  "black-admin-user": require("../assets/icons/black-admin-user.png"),
  "black-add-new-plus": require("../assets/icons/add-new-plus.png"),
  "black-minus-new": require("../assets/icons/black-minus-sign.png"),
  "black-settlement": require("../assets/icons/settlement-request.png"),
  "black-documents": require("../assets/icons/black-documents.png"),
  "black-send-notice": require("../assets/icons/send-notice.png"),
  "black-settlement-commission": require("../assets/icons/black-settlement-commission.png"),
  "black-smartphone": require("../assets/icons/smartphone.png"),
  "black-settlement-request": require("../assets/icons/black-settlement-request.png"),
  "black-next-step": require("../assets/icons/black-next-step.png"),
  // white Icons
  "white-ad-campaign": require("../assets/icons/white-ad-campaign.png"),
  "template-white": require("../assets/icons/template-white.png"),
  "white-dashboard": require("../assets/icons/white-dashboard.png"),
  "white-admin-role": require("../assets/icons/white-admin-role.png"),
  "white-affiliates": require("../assets/icons/white-affiliates.png"),
  "white-audit": require("../assets/icons/white-audit.png"),
  "white-Blog": require("../assets/icons/white-Blog.png"),
  "white-career": require("../assets/icons/white-career.png"),
  "white-cities": require("../assets/icons/white-cities.png"),
  "white-commission": require("../assets/icons/white-commission.png"),
  "white-customers": require("../assets/icons/white-customers.png"),
  "white-FAQ": require("../assets/icons/white-FAQ.png"),
  "white-FAQ2": require("../assets/icons/white-FAQ2.png"),
  "white-homepage": require("../assets/icons/white-homepage.png"),
  "white-admin-user": require("../assets/icons/white-admin-user.png"),
  "white-free-trial": require("../assets/icons/white-free-trial.png"),
  "white-invoice": require("../assets/icons/white-invoice.png"),
  "white-membership-plan": require("../assets/icons/white-membership-plan.png"),
  "white-pages": require("../assets/icons/white-pages.png"),
  "white-partners": require("../assets/icons/white-partners.png"),
  "white-professionals": require("../assets/icons/white-professionals.png"),
  "white-promo-code": require("../assets/icons/white-promo-code.png"),
  "white-user-manual": require("../assets/icons/white-user-manual.png"),
  "white-about": require("../assets/icons/white-white-about.png"),
  "white-admin-management": require("../assets/icons/white-admin-management.png"),
  "white-Business-categories": require("../assets/icons/white-Business-categories.png"),
  "white-business": require("../assets/icons/white-business.png"),
  "white-cashback": require("../assets/icons/white-cashback.png"),
  "white-channel-partner": require("../assets/icons/white-channel-partner.png"),
  "white-contact": require("../assets/icons/white-contact.png"),
  "white-contant-management": require("../assets/icons/white-contant-management.png"),
  "white-list": require("../assets/icons/white-list.png"),
  "white-master-data": require("../assets/icons/white-master-data.png"),
  "white-media": require("../assets/icons/white-media.png"),
  "white-partner-management": require("../assets/icons/white-partner-management.png"),
  "white-promotion-management": require("../assets/icons/white-promotion-management.png"),
  "white-setting": require("../assets/icons/white-setting.png"),
  "white-testimonial": require("../assets/icons/white-testimonial.png"),
  "white-user-management": require("../assets/icons/white-user-management.png"),
  "white-wallet-cashback": require("../assets/icons/white-wallet-cashback.png"),
  "white-upgrade-plan": require("../assets/icons/white-upgrade-plan.png"),
  "black-upgrade-plan": require("../assets/icons/upgradePlan.png"),
  "document-generated-legal": require("../assets/icons/docGenerate.png"),
  "document-signed-legal": require("../assets/icons/docSigned.png"),
  "ready-email-sent-legal": require("../assets/icons/sent.png"),
  "sent-email": require("../assets/icons/emailSent.png"),
  "download-bulk-pdf": require("../assets/icons/download.png"),
  "deactivate-user": require("../assets/icons/deactivateUsers.png"),
  "up-pointer": require("../assets/icons/pointing-up.png"),
};
